/* Your styles goes here. */
.App {
  height: 100vh;
  background-color: #0d1116;
  text-align: center;
  color: white;
}

p {
  margin: 0;
}

.container {
  min-height: 100%;
  background-color: #181818;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-container {
  padding-top: 30px;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
}

.title {
  margin: 0;
  font-size: 35px;
  font-weight: bold;
  color: white;
}

.subtitle {
  font-size: 25px;
  color: white;
}

.left {
  text-align: left;
  margin-left: 10%;
}

.logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.right {
  display: flex;
  background: -webkit-linear-gradient(left, #ff8177, #ff6fdf);
  border-radius: 16px;
  padding: 12px 20px;
  height: 99%;
  margin-right: 10%;
  margin-top: 10px;
  border: 1px solid white;
}

.content-container {
  background-color: #a200d6;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border: 1px solid white;
}

.connect-wallet-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 550px;
}

.connect-wallet-container img {
  padding-bottom: 20px;
}

.connect-wallet-button {
  background-image: linear-gradient(
    to right,
    #ff8177 0%,
    #ff867a 0%,
    #ff8c7f 21%,
    #f99185 52%,
    #cf556c 78%,
    #b12a5b 100%
  );
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #a200d6, #ff6fdf);
  font-size: 18px;
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin: 20px 10px;
  border-radius: 20px;
  padding: 0px 2rem;
}

.mint-button:disabled {
  background: #550072;
  color: rgb(209, 209, 209);
  cursor: not-allowed;
}

.mint-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-button {
  border-radius: 50%;
  cursor: pointer;
  margin-left: 3px;
  top: 20px;
  border: 0px;
  font-size: 12px;
  background-color: transparent;
}

.edit-icon {
  margin-top: 3px;
  width: 16px;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.form-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

input {
  border: 0;
  border-radius: 12px;
  background-color: black;
  color: white;
  letter-spacing: 1px;
  font-size: 26px;
  width: 400px;
  margin-bottom: 10px;
  padding: 10px 20px;
  text-align: center;
}

input::placeholder {
  color: gray;
  letter-spacing: 2px;
}

input:focus {
  outline: none;
}

.first-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.tld {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  color: white;
  /* left: -70px; */
  right: 22px;
  margin: 0;
  padding: 0;
  margin-bottom: 13px;
}

.mint-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.mint-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 10px 15px;
  font-size: 18px;
  background-color: #c4b7fa;
  color: black;
  min-width: 150px;
  max-width: fit-content;
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.2);
}

/* Different background-colour for every nth mint-item */
.mint-item:nth-child(2n) {
  background-color: lightblue;
}

.mint-item:nth-child(3n) {
  background-color: lightpink;
}

.mint-item:nth-child(5n) {
  background-color: lightgreen;
}

.link {
  color: black;
  text-decoration: none;
}

.underlined {
  text-decoration: underline;
}

.underlined:hover {
  cursor: pointer;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.record {
  position: relative;
}

.record::before {
  color: grey;
  position: absolute;
  top: 25%;
  left: -140px;
  font-size: 1.3em;
}

#addr::before {
  content: 'Address : ';
}

#desc::before {
  content: 'Description : ';
}

#email::before {
  content: 'Email : ';
}

#website::before {
  content: 'Website : ';
}

#avatar::before {
  content: 'Avatar URL : ';
}

#twitter::before {
  content: 'Twitter : ';
}

.readonly {
  color: rgb(221, 221, 221);
}

.avatar {
  width: 270px;
  height: 270;
  border: 1px solid white;
}
